$(function () {
    $('.activities-home-slider').slick({
        arrows: true,
        prevArrow: '<button type="button" class="button-slider button-slider--prev"><span class="material-icons button-slider__icon">chevron_left</span></button>',
        nextArrow: '<button type="button" class="button-slider button-slider--next"><span class="material-icons button-slider__icon">chevron_right</span></button>',
        centerMode: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        centerPadding: '40px',
        responsive: [
            {
                breakpoint: 428,
                settings: {
                    slidesToShow: 1.4,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    centerMode: false, 
                }
            },
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerMode: false, 
                }
            }
        ]
    })
});